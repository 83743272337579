import React, { useEffect, useState } from "react";
import ContentViewModal from "../component/contentViewModal.component";
import Header from "../component/header.component";
import DataTable from "../component/table.component";
import TableContent from "../component/tableContent.component";
import moment from "moment";
import "../assets/all.css";
import { Button, Tabs, Modal } from "antd";
import {
  getAppPortal,
  getRelatedModelItems,
  getModelDesign,
  getModelItems,
  getApplicationTranslation,
} from "../_apis/_get";
import { useParams, useNavigate } from "react-router";
import LoadingSpinner from "../component/LoadingSpinner.component";
import CreateItemForm from "../component/CreateItemForm";
import { createItem, createModelItem } from "../_apis/_post";
import { isDateField, isRelatedField, isRequiredField } from "../_utils/is";
import CloudLoader from "../Loaders/ClourLoader/CloudLoader";
import PageTitleMeta from "../component/pageTitleMeta";
import useLang from "../_context/useLang";
import { use } from "i18next";
import { withTranslation } from "react-i18next";
const { TabPane } = Tabs;

const CreateData = ({ t }) => {
  const [menuAppItems, setMenuAppItems] = useState();
  const [currentModel, setCurrentModel] = useState();
  const [modelItemsData, setModelItemsData] = useState();
  const [currentModelPerm, setCurrentModelPerm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modelSchema, setModelSchema] = useState();
  const [rowCount, setRowCount] = useState();
  const [addNewItem, setAddNewItem] = useState(false);
  const [relatedFields, setRelatedFields] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(false);
  const [modelDesign, setModelDesign] = useState(false);
  const [currentTab, setCurrentTab] = useState(false);
  const [isMainLoader, setIsMainLoader] = useState(true);
  let navigate = useNavigate();
  const appId = 3;

  const handleInitModelSchema = async () => {
    let appPortalData;

    try {
      appPortalData = await getAppPortal(appId);
    } catch (e) {
      // setIsMainLoader(true);
      navigate("/404");
    }

    if (appPortalData) {
      setMenuAppItems(appPortalData.models);
      let firstModal;
      let count = 0;
      Object.keys(appPortalData.models).forEach((appPortal) => {
        if (count == 0) {
          firstModal = appPortal;
          count = count + 1;
        }
      });
      setCurrentTab(firstModal);

      if (appPortalData && appPortalData.company_info) {
        setCompanyDetails(appPortalData.company_info);
      }
      if (
        appPortalData.models[firstModal] &&
        appPortalData.models[firstModal].perms
      ) {
        setCurrentModelPerm(appPortalData.models[firstModal].perms);
      }
      if (
        appPortalData.models[firstModal] &&
        appPortalData.models[firstModal].model
      ) {
        setCurrentModel(appPortalData.models[firstModal].model);
      }

      setIsMainLoader(false);
    }
    //  else {
    //   window.location.replace(`${REACT_APP_REDIRECT_URL}`);
    // }
  };

  useEffect(() => {
    handleInitModelSchema();
    // return () => {};
  }, []);

  const handleInitModelData = async () => {
    setIsLoading(true);
    const modelItems = await getModelItems(
      appId,
      currentModel && currentModel.id ? currentModel.id : 11,
      currentLang && currentLang == "ar" ? 6 : 1
    );
    const { data, schema } = modelItems;

    const modelDesigns = await getModelDesign(
      appId,
      currentModel && currentModel.id ? currentModel.id : 11
    );

    setModelDesign(modelDesigns);
    setModelItemsData(data);
    setModelSchema(schema);
    setRowCount(data.length);

    if (modelDesigns) {
      let relatedFields = [];
      modelDesigns.containers.forEach((mainContainer) => {
        mainContainer.containers.forEach((subContainer) => {
          subContainer.fields.forEach((field) => {
            if (isRelatedField(field.data_type)) {
              if (
                !relatedFields[field.related_relation[0].model] &&
                field.related_relation[0].model !== 0
              ) {
                relatedFields.push(field.related_relation[0].model);
              }
            }
          });
        });
      });
      // if (relatedFields && relatedFields.length > 0) {
      //   const res = await Promise.all(
      //     relatedFields.map(async (field) => getRelatedModelItems(appId, field))
      //   );
      //   if (res) {
      //     let obj = {};
      //     relatedFields.forEach((item, index) => {
      //       obj[item] = res[index].data;
      //     });
      //     setRelatedFields(obj);
      //   }
      // }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (currentModel) {
      handleInitModelData();
    }
    return () => {};
  }, [currentModel]);

  function callback(value) {
    setCurrentTab(value);
    setCurrentModel(menuAppItems[value].model);
    setCurrentModelPerm(menuAppItems[value].perms);
  }

  const handleAddItem = () => {
    setAddNewItem(true);
  };

  const handleSubmit = async (values) => {
    let obj = {};

    if (modelDesign) {
      modelDesign.containers.forEach((mainContainer) => {
        mainContainer.containers.forEach((subContainer) => {
          let fields = {};
          subContainer.fields.forEach((field) => {
            const required = isRequiredField(field);

            if (field && values[field.id]) {
              if (isDateField(field.data_type)) {
                fields[field.id] = moment(values[field.id]).format(
                  "YYYY-MM-DD"
                );
              } else {
                fields[field.id] = values[field.id];
              }
            }
          });
          obj[subContainer.id] = fields;
        });
      });
    }

    if (obj) {
      const res = await createModelItem(obj, currentModel.id);

      setAddNewItem(false);
      handleInitModelData();
    }
  };

  const props = {
    currentModel,
    currentModelPerm,
    modelItemsData,
    modelSchema,
    handleAddItem,
    handleInitModelData,
    handleSubmit,
    relatedFields,
    modelDesign,
  };
  const { currentLang, dataTranslation } = useLang();

  return (
    <>
      {isMainLoader ? <CloudLoader /> : null}
      {companyDetails ? (
        <PageTitleMeta title={companyDetails.name} />
      ) : (
        <PageTitleMeta />
      )}

      <Header companyDetails={companyDetails} />
      <div className="container">
        <div className="tab custom-tabs">
          <Tabs
            defaultActiveKey={currentTab ? currentTab : 11}
            className="tablinks"
            value={currentTab}
            onChange={callback}
            key={Math.random()}
          >
            {menuAppItems
              ? Object.keys(menuAppItems).map((key) => {
                  if (
                    menuAppItems[key] &&
                    menuAppItems[key].model &&
                    menuAppItems[key].model.name
                  ) {
                    // arabicModelData &&
                    // arabicModelData[key] &&
                    // arabicModelData[key].name
                    // ? arabicModelData[key].name
                    // : menuAppItems[key].model.name

                    const tabName =
                      // t(menuAppItems[key].model.name);
                      dataTranslation &&
                      dataTranslation.model &&
                      dataTranslation.model[key] &&
                      dataTranslation.model[key].name
                        ? dataTranslation.model[key].name
                        : menuAppItems[key].model.name;

                    return (
                      <TabPane
                        tab={t(tabName)}
                        value={key}
                        key={key}
                        className="tabcontent"
                      ></TabPane>
                    );
                  }
                })
              : null}
          </Tabs>
        </div>
      </div>

      {/* {currentModelPerm && currentModelPerm.POST == true ? (
        <Button onClick={handleAddItem}>Add item</Button>
      ) : null} */}

      {isLoading ? (
        <LoadingSpinner />
      ) : rowCount == 1 ? (
        <section className="table-content-sec">
          <div className="container">
            <div className="row">
              <div className="col col-6">
                <DataTable
                  props={props}
                  type={"single_record_show"}
                  key={Math.random()}
                />
              </div>
            </div>
          </div>
        </section>
      ) : menuAppItems ? (
        <section className="quotes-table-sec">
          <div className="container">
            <div className="row">
              <div className="col col-12">
                <DataTable props={props} key={Math.random()} />
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {addNewItem ? (
        <Modal
          visible={addNewItem}
          onCancel={() => {
            setAddNewItem(false);
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          className={currentLang == "ar" ? "rtl" : "ltr"}
        >
          <CreateItemForm
            modelSchema={modelSchema}
            handleSubmit={handleSubmit}
            relatedFields={relatedFields}
            modelDesign={modelDesign}
            modelItemsData={modelItemsData}
            key={Math.random()}
          />
        </Modal>
      ) : null}

      {/* <section className="content-view-modal-sec relative">
        <img className="sec-bg-img" src="assets/images/right-glob.png" alt="" /> */}

      {/* THIS IMG FOR 2nd PAGE */}
      {/* <img className="full-sec-bg-img" src="assets/images/home-bg-2.png" alt="" /> */}

      {/* <div className="container">
          <div className="row">
            <div className="col col-6">
              <ContentViewModal />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};
export default withTranslation()(CreateData);
