import React, { useEffect, useState } from "react";
import ContentViewModal from "./contentViewModal.component";
import { Modal, Button, Table, Pagination } from "antd";
import TableContent from "./tableContent.component";
import { isDateField, isRelatedField } from "../_utils/is";

import { relatedToString } from "../_utils/set";
import TableItems from "./templateTable";
import CreateItemForm from "./CreateItemForm";
import moment from "moment";
import { updateItemData } from "../_apis/_put";
import parse from "html-react-parser";
import useLang from "../_context/useLang";
import { withTranslation } from "react-i18next";
import { getApplicationTranslation } from "../_apis/_get";
const DataTable = ({ t, props, type }) => {
  const {
    currentModel,
    modelSchema,
    currentModelPerm,
    modelItemsData,
    handleAddItem,
    modelDesign,
    handleInitModelData,
    relatedFields,
    handleSubmit,
  } = props;
  const [viewmodeldata, setViewModelData] = useState();
  const [viewClick, setViewClick] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modelIds, setModelIds] = useState();
  const [viewDetailmodeldata, setViewDetailModelData] = useState();
  const [viewDetailClick, setViewDetailClick] = useState(false);
  const [isModalDetailVisible, setIsDetailModalVisible] = useState(false);
  const [arabicFieldData, setArabicFieldData] = useState(null);
  const [arabicModelTitle, setArabicModelTitle] = useState();
  const { currentLang, dataTranslation, basicTranslations } = useLang();
  const appId = 3;
  const modelId = currentModel && currentModel.id ? currentModel.id : null;
  useEffect(() => {
    setArabicModelTitle(
      dataTranslation &&
        modelId &&
        dataTranslation.model &&
        dataTranslation.model[modelId] &&
        dataTranslation.model[modelId].name
        ? dataTranslation.model[modelId].name
        : null
    );
  }, [arabicModelTitle]);

  const handleViewModelData = (key) => {
    setViewModelData(key);
    setViewClick(true);
    setIsModalVisible(true);
  };
  const handleViewDetailsModelData = (key) => {
    setViewDetailModelData(key);
    setViewDetailClick(true);
    // setIsDetailModalVisible(true);
  };

  const prop = {
    ...props,
    viewDetailmodeldata,
    type,
    relatedFields,
    handleViewModelData,
    handleViewDetailsModelData,
    // arabicFieldData,
  };

  const handleClose = () => {
    setViewClick(false);
    setViewDetailClick(false);
  };
  const updateItems = async (values) => {
    let obj = {};
    if (modelDesign) {
      modelDesign.containers.forEach((mainContainer) => {
        mainContainer.containers.forEach((subContainer) => {
          let fields = {};
          subContainer.fields.forEach((field) => {
            if (field && values[field.id]) {
              if (isDateField(field.data_type)) {
                fields[field.id] = moment(values[field.id]).format(
                  "YYYY-MM-DD"
                );
              } else {
                fields[field.id] = values[field.id];
              }
            }
          });
          obj[subContainer.id] = fields;
        });
      });
    }

    if (obj) {
      const response = await updateItemData(3, viewmodeldata, obj);
      handleInitModelData();
      handleClose();
    }
  };
  // const handleAddItem = () => { };
  return (
    <>
      <div className="quotes-table-wrapper" key={Math.random()}>
        <div className="quotes-table-title title-btn-row" key={Math.random()}>
          <h4>
            {t(
              // currentModel && currentModel.name ? currentModel.name : null
              arabicModelTitle
                ? arabicModelTitle
                : currentModel && currentModel.name
                ? currentModel.name
                : null
            )}
          </h4>
          {currentModelPerm && currentModelPerm.POST == true ? (
            <Button onClick={handleAddItem}>
              {t(
                "add item"
                // basicTranslations && basicTranslations["add item"]
                //   ? basicTranslations["add item"]
                //   : "Add Item"
              )}
            </Button>
          ) : null}
        </div>
        {type && modelItemsData ? (
          <TableContent props={prop} type={type} key={Math.random()} />
        ) : modelItemsData ? (
          <div className="quotes-table-inner" key={Math.random()}>
            {modelItemsData ? (
              <TableItems
                itemsPerPage={5}
                modelSchema={modelSchema}
                props={prop}
                key={Math.random()}
              />
            ) : null}
          </div>
        ) : null}
      </div>

      {viewClick ? (
        <>
          <Modal
            visible={viewClick}
            onCancel={() => {
              handleClose();
            }}
            key={Math.random()}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className={currentLang == "ar" ? "rtl" : "ltr"}
          >
            <CreateItemForm
              modelSchema={modelSchema}
              handleSubmit={updateItems}
              relatedFields={relatedFields}
              modelDesign={modelDesign}
              modelItemsData={modelItemsData}
              viewmodeldata={viewmodeldata}
              key={Math.random()}
            />
            {/* <ContentViewModal
              modelSchema={modelSchema}
              viewClick={viewClick}
              handleClose={handleClose}
              handleInitModelData={handleInitModelData}
              modelDesign={modelDesign}
            /> */}
          </Modal>
        </>
      ) : null}
      {viewDetailClick ? (
        <>
          <Modal
            visible={viewDetailClick}
            onCancel={() => {
              handleClose();
            }}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className={currentLang == "ar" ? "rtl" : "ltr"}
            key={Math.random()}
          >
            <TableContent props={prop} key={Math.random()} />
          </Modal>
        </>
      ) : // <div className="cs-form-modal">
      //   <div className="container">
      //     <div className="row">
      //       <div className="col col-6">
      //         <button className="closeBtn" onClick={handleClose}></button>
      //         <TableContent props={prop} />
      //       </div>
      //     </div>
      //   </div>
      // </div>

      null}
    </>
  );
};
export default withTranslation()(DataTable);
