import i18n from "i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translations: {},
  },
  ar: {
    translations: {},
  },
  tr: {
    translations: {},
  },
};
i18n
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    lowerCaseLng: true,
    cleanCode: true,
    preload: ["ar"],
    fallbackLng: "ar",
    debug: false,
    ns: ["translations"],
    defaultNS: ["translations"],
    keySeparator: ".",
    // lng: localStorage.getItem("currentLang")
    //   ? localStorage.getItem("currentLang")
    //   : "en",
    // fallbackLng: localStorage.getItem("currentLang")
    //   ? localStorage.getItem("currentLang")
    //   : "en",
    // debug: true,
    // keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
