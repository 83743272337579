import { message } from "antd";
import i18next from "i18next";
import { orderBy, get as getString } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { signIn, signOut } from "../_apis/_post";

const GlobalContext = React.createContext({});
const queryString = require("query-string");

const GlobalState = ({ children, cookies }) => {
  const handleSignOut = async () => {
    try {
      await signOut();
      cookies.set("s_id", null, {
        path: "/",
      });
      cookies.set("user", null, {
        path: "/",
      });
      localStorage.removeItem("user");
      localStorage.removeItem("CompanyPortalUser");
      window.location.reload(false);
      // window.location.href = `/signin${isMobileApp() ? `?is_mobile=true` : ``}`
    } catch (e) {}
  };

  const handleSuccessfulSignIn = (res, location) => {
    const loginSuccesfull = res.status === 200 ? true : false;
    const getUser = {
      s_id: res.headers.s_id,
      details: res.data.data,
      id: res.data.data.id,
    };

    cookies.set("s_id", getUser.s_id, {
      path: "/",
    });
    cookies.set("user", getUser, {
      path: "/",
    });

    localStorage.setItem("user", JSON.stringify(getUser));
    localStorage.setItem("CompanyPortalUser", JSON.stringify(getUser));
    localStorage.setItem("userEmail", JSON.stringify(getUser.details.email));
    localStorage.setItem("firstLogin", true);
    localStorage.setItem("s_id", getUser.s_id);
    const { redirectTo } = queryString.parse(window.location.search);
    // if (loginSuccesfull) {
    //   if (redirectTo && redirectTo !== "null") {
    //     window.location.href = `${redirectTo}${
    //       isMobileApp() ? `&is_mobile=true` : ``
    //     }`;
    //   } else if (location && location.state && location.state.from) {
    //     let fromPath = location.state.from.pathname;
    //     let searchParams = location.state.from.search;
    //     if (fromPath) {
    //       fromPath = fromPath + (searchParams ? searchParams : "");
    //     }
    //     window.location.href = fromPath;
    //   } else if (res.data.data && res.data.data.default_application) {
    //     window.location.href = getLinkDashboard({
    //       route: "application",
    //       appId: res.data.data.default_application,
    //       appName: i18next.t(
    //         `application.${res.data.data.default_application}.name`
    //       ),
    //     });
    //   } else {
    //     window.location.href = `/${isMobileApp() ? `?is_mobile=true` : ``}`;
    //   }
    // }
    window.location.reload(false);
    return getUser;
  };

  const handleSignIn = async (username, password) => {
    try {
      const res = await signIn({
        username,
        password,
      });

      const user = handleSuccessfulSignIn(res);
    } catch (e) {
      throw Error(getString(e.response, "data.meta.errors.SYS_MSG"));
    }
  };
  return (
    <GlobalContext.Provider
      value={{
        handleSignIn,
        handleSuccessfulSignIn,
        handleSignOut,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export { GlobalContext, GlobalState };
