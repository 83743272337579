import React, { Suspense, useState } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import CreateData from "./pages/createData.component";
import HomePage from "./pages/homePage.component";
import HomePage2 from "./pages/homePage2.component";
import CloudLoader from "./Loaders/ClourLoader/CloudLoader";
import { withCookies } from "react-cookie";
import SignInFormWrapper from "./pages/signInForm.component";
import RequestData from "./pages/requestData.component";
import CompinedProviders from "./_context/CombinedPorviders";
import NotFound from "./public/404";
const renderLoader = () => <CloudLoader />;
function App({ cookies }) {
  const [isLoading, setIsLoading] = useState(false);
  const { REACT_APP_REDIRECT_URL } = process.env;
  // if (window.location.pathname === "/") {
  //   setIsLoading(true);
  //   window.location.replace(`${REACT_APP_REDIRECT_URL}`);
  // }

  return (
    <>
      {/* {isLoading ? <CloudLoader /> : null} */}
      {/* <Suspense fallback={renderLoader()}> */}
      <CompinedProviders cookies={cookies}>
        <div className="App">
          <Router>
            <Routes>
              <Route
                path="/"
                //  path="/:appId"
                element={<HomePage />}
              />
              {/* <Route exact path="/homepage-2" element={<HomePage2 />} /> */}
              <Route exact path="/createdata" element={<CreateData />} />
              <Route exact path="/request" element={<RequestData />} />
              <Route exact path="/login" element={<SignInFormWrapper />} />
              <Route path="/404" element={<NotFound />} />
            </Routes>
          </Router>
        </div>
      </CompinedProviders>
      {/* </Suspense> */}
    </>
  );
}

export default withCookies(App);
