import React from "react";

import { withTranslation } from "react-i18next";

// import LinkCustom from "../../components/_common/LinkCustom/LinkCustom";

const NotFound = ({ t }) => {
  return (
    <div>
      <div style={{ width: `100vw`, height: `80vh`, display: `flex` }}>
        <div style={{ margin: `auto`, textAlign: `center` }}>
          <img
            style={{ width: `200px` }}
            src="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
            alt=""
          />
          <h1 style={{ fontSize: `90px` }}>404</h1>
          <p>{t("page not found")}</p>
          {/* <LinkCustom
            to="/"
            style={{
              backgroundColor: "#465b95",
              color: `#fff`,
              padding: `5px 30px`,
              borderRadius: `5px`,
            }}
          >
            {t("home")}
          </LinkCustom> */}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(NotFound);
