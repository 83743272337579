import { useContext } from "react";

import { GlobalContext } from "./GlobalState";

function useGlobalContext() {
  const globalState = useContext(GlobalContext);

  return {
    ...globalState,
  };
}

export default useGlobalContext;
