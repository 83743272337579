import { message } from "antd";
import { get } from "lodash";

import { signOut } from "./_post";
export const setDistroyGlobalLoading = () => {
  message.destroy();
};
const handleError = (err, path) => {
  const { status, data } = err.response || err;
  let errorMessages = "";

  try {
    const errors = Object.values(get(data, "meta.errors", {}));
    setDistroyGlobalLoading(false);
    errorMessages = errors.join(", ");
    message.error({ content: errorMessages, key: errorMessages }, 15);
  } catch (e) {
    message.error("Something went wrong!", 15);
  }

  setTimeout(() => {
    if (status === 403) {
      if (data && data.meta && !data.meta.user_logged_in) {
        signOut();
      }
    }
  }, 1500);
};

export default handleError;
