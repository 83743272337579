import { Input, Switch, InputNumber, Form, DatePicker, Select } from "antd";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import useLang from "../_context/useLang";
import {
  isBooleanField,
  isDateField,
  isListField,
  isNumberField,
  isRelatedField,
  isRequiredField,
  isStringField,
} from "../_utils/is";
import { setRelatedSearchToOptions } from "../_utils/set";

const { Option } = Select;
const RenderFields = ({ t, model, initialValues, relatedFields, form }) => {
  const initialValue =
    initialValues && initialValues[model.id] ? initialValues[model.id] : "";

  const required = isRequiredField(model);
  const message = t("please input this field");
  const { dataTranslation } = useLang();

  const onSelectChange = (value) => {
    form.setFieldsValue({
      [model.id]: value,
    });
  };
  // const fieldLabel = model && model.name ? model.name : null;
  const fieldLabel =
    dataTranslation &&
    dataTranslation.field &&
    dataTranslation.field[model.id] &&
    dataTranslation.field[model.id].name
      ? dataTranslation.field[model.id].name
      : model.name;
  if (isStringField(model.data_type)) {
    return (
      <>
        <Form.Item
          label={t(fieldLabel)}
          name={model.id}
          key={Math.random()}
          rules={
            required
              ? [
                  {
                    required: { required },
                    message: { message },
                  },
                ]
              : null
          }
        >
          <Input
            //  defaultValue={initialValue}
            key={Math.random()}
          />
        </Form.Item>
      </>
    );
  } else if (isBooleanField(model.data_type)) {
    return (
      <Form.Item
        label={t(fieldLabel)}
        name={model.id}
        key={Math.random()}
        rules={
          required
            ? [
                {
                  required: { required },
                  message: { message },
                },
              ]
            : null
        }
      >
        <Switch
          // defaultValue={initialValue}
          key={Math.random()}
        />
      </Form.Item>
    );
  } else if (isNumberField(model.data_type)) {
    return (
      <Form.Item
        label={t(fieldLabel)}
        name={model.id}
        key={Math.random()}
        rules={
          required
            ? [
                {
                  required: { required },
                  message: { message },
                },
              ]
            : null
        }
      >
        <InputNumber
          //  defaultValue={initialValue}
          key={Math.random()}
        />
      </Form.Item>
    );
  } else if (isDateField(model.data_type)) {
    return (
      <>
        <Form.Item
          label={t(fieldLabel)}
          name={model.id}
          key={Math.random()}
          rules={
            required
              ? [
                  {
                    required: { required },
                    message: { message },
                  },
                ]
              : null
          }
        >
          <DatePicker />
        </Form.Item>
      </>
    );
  } else if (isListField(model.data_type)) {
    return (
      <>
        <Form.Item
          label={t(fieldLabel)}
          name={model.id}
          key={Math.random()}
          rules={
            required
              ? [
                  {
                    required: { required },
                    message: { message },
                  },
                ]
              : null
          }
        >
          {console.log(initialValue, "initial")}
          <Select
            key={Math.random()}
            defaultValue={
              initialValue ? initialValue : model.field_options[0].id
            }
            onChange={onSelectChange}
          >
            {model.field_options.map((field) => {
              return <Option value={field.id}>{field.text}</Option>;
            })}
          </Select>
        </Form.Item>
      </>
    );
  } else if (isRelatedField(model.data_type)) {
    let options;
    if (relatedFields && relatedFields[model.related_relation[0].model]) {
      options = setRelatedSearchToOptions(
        relatedFields[model.related_relation[0].model]
      );
    }
    const defaultVal =
      initialValue && initialValue.related_field_object
        ? Object.keys(initialValue.related_field_object)[0]
        : null;

    return (
      <>
        <Form.Item
          label={t(fieldLabel)}
          name={model.id}
          key={Math.random()}
          rules={
            required
              ? [
                  {
                    required: { required },
                    message: { message },
                  },
                ]
              : null
          }
        >
          {console.log(options, defaultVal)}
          <Select
            key={Math.random()}
            defaultValue={defaultVal ? defaultVal : options && options[0].value}
            onChange={onSelectChange}
          >
            {options &&
              options.length &&
              options.map((field) => {
                return <Option value={field.value}>{field.text}</Option>;
              })}
          </Select>
        </Form.Item>
      </>
    );
  }
};
export default withTranslation()(RenderFields);
