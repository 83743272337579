import { message } from "antd";
import i18next from "i18next";
import { isArray, isNumber, isObject, isString } from "lodash";

export const setRelatedSearchToOptions = (results) => {
  if (!results || !isArray(results)) {
    return [];
  }

  const temOptions = [];

  results.forEach((i) => {
    const itemId = Object.keys(i)[0];

    temOptions.push({
      key: itemId,
      value: itemId,
      text: relatedToString(i),
    });
  });

  return temOptions;
};

export const relatedToString = (item) => {
  const key = Object.keys(item)[0];
  const keys = Object.keys(item[key]);

  let string = "";

  const strAndNum = keys
    .filter(
      (k) =>
        isObject(item[key][k]) ||
        isString(item[key][k]) ||
        isNumber(item[key][k])
    )
    .slice(0, 3);
  strAndNum.map((k, index) => {
    if (item[key][k]) {
      if (isObject(item[key][k]) && item[key][k]["related_field_object"]) {
        const innerKeys = Object.keys(item[key][k]["related_field_object"]);
        let text = innerKeys.map((innerKey) =>
          Object.values(item[key][k]["related_field_object"][innerKey])
        );
        string = string + text[0].join("-");
        string = index < strAndNum.length - 1 ? string + " / " : string;
      } else if (isString(item[key][k]) || isNumber(item[key][k])) {
        string = string + item[key][k];
        string = index < strAndNum.length - 1 ? string + " / " : string;
      }
    }
  });

  // string =  string.charAt(string.length -2 ) === "/" ?   string.substring(0, string.lastIndexOf("/ ")) :string

  return string;
};
export const setGlobalLoading = (loading, key) => {
  if (loading) {
    const hide = message.loading("Loading", 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);
  } else {
    message.destroy({ key });
  }
};

export const setLocalStorageLangOptions = (lngOptions) =>
  localStorage.setItem(
    "langOptions",
    JSON.stringify({
      // lng: lngOptions.lng,
      resources: lngOptions.resources,
    })
  );
