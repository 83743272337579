// import { get, get as getFromObj, isArray, isEmpty, merge } from "lodash";
// import { get } from "lodash";
// import axiosGet from "./methods";
import axios from "axios";
import { isArray } from "lodash";
import useLang from "../_context/useLang";
import apiConfig from "./api.config";
import handleError from "./handleError";
import { getHeaders, setApiResponse, get } from "./methods";
const baseUrlStr = apiConfig.baseUrl;
const baseUrl = axios.create({
  baseURL: baseUrlStr,
});
export const getUser = localStorage.user ? JSON.parse(localStorage.user) : null;

const headers = {
  Authorization:
    "dev_token_tH00lFTw2FZJoyzXW3Cn8vLEaYUXQMQrSYNqUxFEOkOFPAHAmvnSVDNqdU3wOOAOBivdK9qopxRCVRVXKhoM1FiNkuuBXf17YrwpLcdwN5Ag36RUz0luw_",
  "Content-Type": "application/json",
};
export const getAppPortal = async (appId) => {
  const { data } = await get(`application/${appId}/portal`);

  return data;
};

export const getModelItems = async (appId, modelId, currentLang) => {
  const { data } = await get(
    `application/${appId}/item_brief?model=${modelId}&schema=true&language=${currentLang}`
  );

  return data;
};

export const getRelatedModelItems = async (appId, modelId) => {
  const { data } = await get(
    `application/${appId}/item_data/search?model=${modelId}&schema=true&api_total_count=true&system_fields=1&keyword=`
  );

  return data;
};

export const getModelDesign = async (appId, modelId) => {
  const { data } = await get(
    `application/${appId}/model/${modelId}/model_design?api_depth=2`
  );

  return data;
};

export const getUserGeoLocation = async () =>
  get(`https://json.geoiplookup.io/`);

export const getLangId = (code) =>
  code === "tr" ? "122" : code === "ar" ? "6" : "1";
export const getLangCode = (id) =>
  Number(id) === 122 ? "tr" : Number(id) === 6 ? "ar" : "en";

export const getStructureTranslation = async (langId) => {
  const { data } = await get(`translate/structure?language=${langId}`);

  return data;
};

export const getApplicationTranslation = async (appId, langId) => {
  const api = appId
    ? isArray(appId)
      ? `translate/structure/multiple?language=${langId}&applications=${appId.join()}`
      : `translate/structure?language=${langId}&application=${appId}`
    : null;
  const { data } = await get(api);

  return data;
};
export const getSelectLanguage = async (langId) => {
  const { data } = await get(`select_language?language=${langId}`);

  return data;
};
export const getInterfaceTranslation = async (langId) => {
  const { data } = await get(`translate/interface?language=${langId}`);

  return data;
};
export const getLocalStorageOptions = localStorage.getItem("langOptions")
  ? JSON.parse(localStorage.getItem("langOptions"))
  : null;
export const getLocalStorageLang = localStorage.getItem("i18nextLng");
