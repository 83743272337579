import React from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const PageTitleMeta = ({ t, title }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        {title ? `${t(title)}  ${t("Portal")}` : t("Oneday Portal")}
      </title>
    </Helmet>
  );
};
export default withTranslation()(PageTitleMeta);
