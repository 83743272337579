import { post } from "./methods";
export const signIn = async (user) => {
  const res = await post(`sign_in`, user);

  return res;
};
export const signOut = async () => {
  const { data } = await post(`sign_out`);

  localStorage.removeItem("user");
  localStorage.removeItem("OneDayUser");
  // window.location.href = `/signin${isMobileApp() ? `?is_mobile=true` : ``}`

  return data;
};

export const createModelItem = async (values, modelId) => {
  const { data } = await post(
    `application/3/item_data?model=${modelId}`,
    values
  );
  return data;
};
