import React, { useState } from "react";
import CreateDataForm from "../component/createDataForm.component";
import CreateDataForm1 from "../component/createDataForm1.component";
import "../assets/all.css";
import { Modal } from "antd";

const CreateData = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleOk = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* <CreateDataForm /> */}
      <CreateDataForm1 />
      {/* <span type="primary" onClick={() => setIsModalVisible(true)}>
        Open Modal
      </span>
      
      {isModalVisible ? (
        <Modal
          visible={isModalVisible ? true : false}
          onOk={() => {
            handleOk();
          }}
          onCancel={() => {
            handleOk();
          }}
        >
          something here
        </Modal>
      ) : null} */}
    </>
  );
};
export default CreateData;
