import React from "react";

import { withTranslation } from "react-i18next";
import Cloud from "../Cloud/Cloud";
import * as S from "./styled";
const CloudLoader = ({ t, size }) => {
  return (
    <S.CloudLoaderWrapper>
      <div className={`CloudLoader_main ${size}`}>
        <div className="CloudLoader_main_inner">
          <Cloud color="white" />
          <p>{t("oneday portal")}</p>
        </div>
      </div>
    </S.CloudLoaderWrapper>
  );
};

export default withTranslation()(CloudLoader);
