import { Button, Collapse, Form } from "antd";
import { isObject } from "formik";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import useLang from "../_context/useLang";
import { isRequiredField } from "../_utils/is";
import RenderFields from "./RenderFields";

const { Panel } = Collapse;

const CreateItemForm = ({
  modelSchema,
  handleSubmit,
  relatedFields,
  modelDesign,
  modelItemsData,
  viewmodeldata,
  t,
}) => {
  const [initialValue, setInitialValue] = useState(false);
  const [openCollapse, setOpenCollapse] = useState("0");
  const { currentLang, dataTranslation } = useLang();

  const [form] = Form.useForm();
  useEffect(() => {
    if (modelItemsData) {
      let initialValues = {};
      modelItemsData.forEach((items) => {
        Object.keys(items).forEach((key) => {
          if (viewmodeldata === key) {
            Object.keys(items[key]).forEach((item) => {
              const searchModel = modelSchema.find((i) => i.id == item);
              if (searchModel) {
                const modelName = searchModel.id;

                if (isObject(items[key][item])) {
                  if (items[key][item].related_field_object) {
                    initialValues[modelName] = Object.keys(
                      items[key][item].related_field_object
                    )[0];
                  } else {
                    initialValues[modelName] = items[key][item].id;
                  }
                } else {
                  initialValues[modelName] = items[key][item];
                }
              }
            });
          }
        });
      });

      setInitialValue(initialValues);
      // modelDesign.containers.forEach((mainContainer)=>{
      //   mainContainer.containers.forEach((container)=>{
      //     container.fields.forEach((field)=>{
      //       if(field.id)
      //     })
      //   })
      // })
    }
  }, [modelItemsData]);
  useEffect(() => {
    if (initialValue) {
      Object.keys(initialValue).map((o) => {
        form.setFieldsValue({
          [o]: initialValue[o],
        });
      });
    }
  }, [initialValue]);
  return (
    <>
      {modelDesign &&
        modelDesign.containers.map((mainContainer, index) => {
          if (mainContainer) {
            return (
              <>
                <Form
                  onFinish={handleSubmit}
                  name="add Item"
                  form={form}
                  initialValues={initialValue}
                >
                  {mainContainer.containers.map((subContainer, index) => {
                    return (
                      <>
                        <Collapse defaultActiveKey={openCollapse} key={index}>
                          <Panel
                            header={
                              // t(
                              //   subContainer && subContainer.name
                              //     ? subContainer.name
                              //     : null
                              // )
                              dataTranslation &&
                              dataTranslation.container &&
                              dataTranslation.container[subContainer.id] &&
                              dataTranslation.container[subContainer.id].name
                                ? dataTranslation.container[subContainer.id]
                                    .name
                                : subContainer.name
                            }
                            key={index}
                          >
                            {subContainer.fields.map((field) => {
                              const required = isRequiredField(field);
                              // if (required) {
                              //   handleCollapse(index);
                              // }
                              return (
                                <RenderFields
                                  model={field}
                                  relatedFields={relatedFields}
                                  initialValues={initialValue}
                                  form={form}
                                />
                              );
                            })}
                          </Panel>
                        </Collapse>
                      </>
                    );
                  })}

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </>
            );
          }
        })}
    </>
  );
};

export default withTranslation()(CreateItemForm);
