import React, { useState } from "react";
// import { ErrorMessage, Formik, Field, Form } from "formik";
// import { Form } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import "../assets/all.css";
import Icon from "antd/lib/icon";
import { Alert, Button, Checkbox, Radio, Result, Form } from "antd";
import { t } from "i18next";
import Input from "rc-input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { connect } from "formik";

const SignInForm = ({ form, t, location }) => {
  const [signupSent, setSignupSent] = useState(false);
  const [emailOrMobileToggle, setEmailOrMobileToggle] = useState("email");
  const [showSignoutButton, setShowSignoutButton] = useState(false);
  const [showSignUpButton, setShowSignUpButton] = useState(false);
  const [username, setUsername] = useState("");
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const validateMobileValue = (value) =>
    !/^\+[0-9]\d*$/.test(value) || value.length < 9 || value.length > 16;

  const validateMobile = (rule, value, callback) => {
    if (validateMobileValue(value) && value) {
      callback(
        <div>
          <Alert
            message={t(
              "invalid mobile format it should start with + and length between 8 to 12 digit"
            )}
            type="warning"
            showIcon
          />
        </div>
      );
    }
    callback();
  };
  const handleSignup = async () => {
    form.validateFields(async (err, values) => {
      // if (!err) {
      //   setLoading(true);
      //   try {
      //     const userCountry = await getCountry();
      //     if (userCountry.data && userCountry.data.length) {
      //       values.country = userCountry.data[0].id;
      //     }
      //     values.language = getLangId(i18n.language);
      //     delete values.remember;
      //     values.email = values.username;
      //     values.username = `user${Date.now()}`;
      //     await post("sign_up", values);
      //     setSignupSent(true);
      //   } catch (e) {
      //     setShowSignUpButton(true);
      //   }
      //   setLoading(false);
      // }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      // if (!err) {
      //   setLoading(true);
      //   try {
      //     await handleSignIn(values, location);
      //   } catch (e) {
      //     if (e.message === "User is already signed in") {
      //       setShowSignoutButton(true);
      //     } else {
      //       setTimeout(() => {
      //         setShowSignUpButton(true);
      //       }, 2000);
      //     }
      //   }
      //   setLoading(false);
      // }
    });
  };
  const handleRemember = (e) => {
    setRememberMe(!rememberMe);
    localStorage.setItem("remember", !rememberMe);
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        {signupSent ? (
          <Result
            status="success"
            title={t("process succesful")}
            subTitle={t("please check your email and confirm the link")}
          />
        ) : (
          <div>
            <h2 className="colorPurple">{t("sign in")}</h2>
            <Radio.Group
              defaultValue={"email"}
              onChange={(e) => setEmailOrMobileToggle(e.target.value)}
              className="email-and-mobile-radio-btn"
            >
              <Radio value={"email"}>{t("email")}</Radio>
              <Radio value={"mobile"}>{t("mobile")}</Radio>
            </Radio.Group>
            {emailOrMobileToggle === "email" ? (
              <Form.Item name="username" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            ) : (
              <Form.Item>
                {getFieldDecorator("username", {
                  initialValue: username,
                  rules: [
                    {
                      required: false,
                      message: false,
                    },
                    {
                      validator: validateMobile,
                    },
                  ],
                  // validateTrigger: 'onBlur'
                })(
                  // <Input
                  //   prefix={<Icon type="mobile" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  //   placeholder={t('mobile')}
                  //   autoComplete="off"
                  // />

                  <PhoneInput
                    className="signUp-mobile"
                    international
                    defaultCountry="SA"
                    placeholder={t("mobile")}
                  />
                )}
              </Form.Item>
            )}
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: t("please input your password") },
                ],
              })(
                <Input.Password
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder={t("password")}
                />
              )}
            </Form.Item>
            <Form.Item>
              <div className="flexClass">
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: rememberMe,
                })(
                  <Checkbox onChange={handleRemember}>
                    {t("remember me")}
                  </Checkbox>
                )}
              </div>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t("sign in")}
              </Button>
              {showSignoutButton && (
                <Button
                  type="primary"
                  // onClick={handleSignOut}
                >
                  {t("sign out")}
                </Button>
              )}
              {showSignUpButton && (
                <Button type="primary" onClick={handleSignup}>
                  {t("click here to signup instead")}
                </Button>
              )}
            </Form.Item>
          </div>
        )}
      </Form>
    </>
  );
};

export default SignInForm;
