import apiConfig from "./api.config";
import axios from "axios";
import { message } from "antd";
import { get as getString, isString } from "lodash";
import handleError from "./handleError";
const baseUrlStr = apiConfig.baseUrl;
const baseUrl = axios.create({
  baseURL: baseUrlStr,
});

export const setApiResponse = (res, isGetRequest, displayMessage) => {
  if (isString(res)) {
    throw Error("error");
  }

  if (!isGetRequest) {
    try {
      const apiStatus = res.status;
      let apiMessage;
      if (displayMessage !== "" && displayMessage !== undefined) {
        apiMessage = displayMessage;
      } else {
        apiMessage = res.data.meta.message;
      }

      if (apiStatus === 200 && apiMessage !== "" && apiMessage != undefined) {
        message.success(apiMessage, 4);
      } else if (apiMessage !== "" && apiMessage != undefined) {
        message.error(apiMessage, 15);
      }
    } catch (e) {}
  }
};
export const getSID = () => {
  return localStorage.s_id || "";
};
const headers = {
  Authorization:
    "dev_token_tH00lFTw2FZJoyzXW3Cn8vLEaYUXQMQrSYNqUxFEOkOFPAHAmvnSVDNqdU3wOOAOBivdK9qopxRCVRVXKhoM1FiNkuuBXf17YrwpLcdwN5Ag36RUz0luw_",
  "Content-Type": "application/json",
};
export const getHeaders = () => {
  return {
    headers: {
      Authorization:
        "dev_token_tH00lFTw2FZJoyzXW3Cn8vLEaYUXQMQrSYNqUxFEOkOFPAHAmvnSVDNqdU3wOOAOBivdK9qopxRCVRVXKhoM1FiNkuuBXf17YrwpLcdwN5Ag36RUz0luw_",
      "Content-Type": "application/json",
      s_id: getSID(),
    },
  };
};
export const axiosGet = async (url, data) => {
  const red = await get(
    "https://testapi.1daycloud.com/v3/application/3/portal"
  );
};
export const post = (path, data, hideMessage, message = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlStr}${path}`, JSON.stringify(data), getHeaders())
      .then((res) => {
        if (!hideMessage) {
          setApiResponse(res, false, message);
        }

        resolve(res);
      })
      .catch((err) => {
        handleError(err);
        reject(err);
      });
  });
};

export const get = (path) => {
  return new Promise((resolve, reject) => {
    return axios
      .get(`${baseUrlStr}${path}`, getHeaders())
      .then((res) => {
        setApiResponse(res, true);
        resolve(res.data);
      })
      .catch((err) => {
        handleError(err);
        reject(err);

        return err;
      });
  });
};

export const put = (path, data, hideMessage) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlStr}${path}`, JSON.stringify(data), getHeaders())
      .then((res) => {
        if (!hideMessage) {
          setApiResponse(res);
        }

        resolve(res);
      })
      .catch((err) => {
        handleError(err);
        reject(err);
      });
  });
};
export const del = (path) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrlStr}${path}`, getHeaders())
      .then((res) => {
        setApiResponse(res);
        resolve(res);
      })
      .catch((err) => {
        handleError(err);
        reject(err);
      });
  });
};

export default { baseUrl, axiosGet };
