import styled from "styled-components";

export const CloudLoaderWrapper = styled.div`
  .CloudLoader_main {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #5d6c97;
    display: flex;
    position: fixed;
    z-index: 99;
    top: 0;

    &.small {
    }

    .CloudLoader_main_inner {
      margin: auto;
      // background-color: red;
      text-align: center;
    }

    p {
      color: #fff;
      font-weight: 600;
      text-align: center;
      // transform: translateY(-5px);
    }
  }
`;
