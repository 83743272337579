import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Button, Result } from "antd";
import { Space } from "antd";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { getLangId, getUserGeoLocation } from "../_apis/_get";

import i18n, { t } from "i18next";
import { post, get } from "../_apis/methods";

import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
const SignUpForm = ({ t }) => {
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [linkSent, setLinkSent] = useState(false);
  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const getCountry = async () => {
    const getLocation = await getUserGeoLocation();

    const countryRes = await get(`country?is_supported=true&code2=IN`);

    return countryRes || "";
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("please enter email"),
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Password Must Contain 8 Characters, One Uppercase, One Lowercase and One Number "
      ),
    // confirmPassword: Yup.string().required("Please enter your password"),
    confirmPassword: Yup.string()
      .required("Please enter confirm password")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with Password"
      ),
  });

  const submitFunction = async (value) => {
    setIsLoading(true);
    try {
      value.username = `user${Date.now()}`;
      delete value.confirmPassword;
      const userCountry = await getCountry();
      if (userCountry && userCountry.data && userCountry.data.length) {
        value.country = userCountry.data[0].id;
      }
      if (phoneNumber) {
        value.mobile = phoneNumber;
      }
      value.language = getLangId(i18n.language || "en");
      // value.captcha = true;
      const response = await post("sign_up", value);

      setIsLoading(false);
      setLinkSent(true);
    } catch (e) {}
  };
  const onRecaptchaChange = (value) => {
    // form.setFieldsValue({ captcha: true });
  };
  return (
    <>
      <div className="login-sec">
        <div className="container">
          <div className="row">
            <div className="col col-6 mx-auto">
              {linkSent ? (
                <Result
                  status="success"
                  title="process succesful"
                  subTitle="please check your email and confirm the link"
                />
              ) : (
                <>
                  <div className="formTitle">{t("sign up")}</div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    // validationSchema={editValidationSchema}
                    onSubmit={(value) => {
                      submitFunction(value);
                    }}
                  >
                    {({ submitForm, values, errors }) => (
                      <Form>
                        <div className="input-item">
                          <Space>
                            <UserOutlined />
                          </Space>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("email")}
                            name="email"
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                submitForm();
                              }
                            }}
                          />
                          <ErrorMessage name="email">
                            {(msg) => (
                              <span
                                className="fm_error"
                                style={{ color: "red" }}
                              >
                                {msg}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <PhoneInput
                          value={phoneNumber}
                          international
                          defaultCountry="SA"
                          onChange={setPhoneNumber}
                          name="mobileNumber"
                          // className="signUp-mobile"
                        />

                        <div className="input-item">
                          <Space>
                            <LockOutlined />
                          </Space>
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder={t("password")}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                submitForm();
                              }
                            }}
                          />
                          <Button
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            <Space>
                              {showPassword ? (
                                <EyeInvisibleOutlined />
                              ) : (
                                <EyeOutlined />
                              )}
                            </Space>
                          </Button>
                          <ErrorMessage name="password">
                            {(msg) => (
                              <span
                                className="fm_error"
                                style={{ color: "red" }}
                              >
                                {msg}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="input-item">
                          <Space>
                            <LockOutlined />
                          </Space>
                          <Field
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            placeholder={t("confirm") + t("password")}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                submitForm();
                              }
                            }}
                          />

                          <Button
                            onClick={() => {
                              setShowConfirmPassword(!showConfirmPassword);
                            }}
                          >
                            <Space>
                              {showConfirmPassword ? (
                                <EyeInvisibleOutlined />
                              ) : (
                                <EyeOutlined />
                              )}
                            </Space>
                          </Button>
                          <ErrorMessage name="confirmPassword">
                            {(msg) => (
                              <span
                                className="fm_error"
                                style={{ color: "red" }}
                              >
                                {msg}
                              </span>
                            )}
                          </ErrorMessage>
                          {/* <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={onRecaptchaChange}
                      /> */}
                          {/* <p className="privacy-text">
                            by creating this account, you agree to our{" "}
                            <button>{t("terms of use")}</button> and{" "}
                            <button>{t("privacy policy")}</button>
                          </p> */}
                        </div>
                        <div className="formFooter">
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                          >
                            {t("submit")}
                          </Button>
                          <div className="contant">
                            <button>{t("forgot password?")}</button>
                            <p>
                              {t("already have an account?")}{" "}
                              <button>{t("sign in")}</button>
                            </p>
                            <button>{t("resend activation email")}</button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withTranslation()(SignUpForm);
