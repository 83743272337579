import { Button } from "antd";
import { isObject } from "lodash";
import React, { useEffect, useState } from "react";
import { getModelFields } from "../_apis/_get";
import { isHTML, isRelatedField } from "../_utils/is";
import { relatedToString } from "../_utils/set";
import parse from "html-react-parser";
import useLang from "../_context/useLang";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
const TableContent = ({ props, type, t }) => {
  const {
    modelSchema,
    modelItemsData,
    viewDetailmodeldata,
    currentModelPerm,
    handleViewModelData,
    relatedFields,
  } = props;
  let modelId;
  const { currentLang, dataTranslation } = useLang();
  return (
    <>
      {type ? (
        <>
          <div className="table-content-wrapper">
            <div className="table-content-inner common-box">
              <div className="listing-wrap">
                <ul key={Math.random()}>
                  {modelItemsData.map((modelItem) => (
                    <span key={Math.random()}>
                      {Object.keys(modelItem).map((item) => (
                        <>
                          {Object.keys(modelItem[item]).map((key) => {
                            modelId = item;
                            if (key != "is_active") {
                              const headerTitle = modelSchema.find(
                                (i) => i.id === Number(key)
                              );
                              if (isRelatedField(headerTitle.data_type)) {
                                const str = relatedToString(
                                  modelItem[item][key].related_field_object
                                );
                                return (
                                  <>
                                    <li className="columnName">
                                      <span key={Math.random()}>
                                        {headerTitle && headerTitle.name
                                          ? headerTitle.name
                                          : null}
                                      </span>
                                      <span key={Math.random()}>{str}</span>
                                    </li>
                                  </>
                                );
                              }
                              return (
                                <>
                                  <li className="columnName">
                                    <span key={Math.random()}>
                                      {headerTitle && headerTitle.name
                                        ? headerTitle.name
                                        : null}
                                    </span>
                                    <span key={Math.random()}>
                                      {/* {modelItem[item][key]} */}
                                      {modelItem[item][key] &&
                                      isHTML(modelItem[item][key])
                                        ? parse(modelItem[item][key])
                                        : modelItem[item][key]}
                                    </span>
                                  </li>
                                </>
                              );
                            }
                          })}
                        </>
                      ))}
                    </span>
                  ))}
                </ul>
                {currentModelPerm && currentModelPerm.PUT === true ? (
                  <Button
                    className="btn primary"
                    onClick={() => {
                      handleViewModelData(modelId);
                    }}
                  >
                    EDIT
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="table-content-wrapper" key={Math.random()}>
          <div className="table-content-inner common-box" key={Math.random()}>
            <div className="listing-wrap" key={Math.random()}>
              <ul key={Math.random()}>
                {modelSchema && modelItemsData
                  ? modelSchema.map((schema) => (
                      <>
                        <li key={Math.random()}>
                          <span key={Math.random()}>
                            {/* {schema.name + schema.id} */}
                            {/* {t(schema.name ? schema.name : null)} */}
                            {dataTranslation &&
                            dataTranslation.field &&
                            dataTranslation.field[schema.id] &&
                            dataTranslation.field[schema.id].name
                              ? dataTranslation.field[schema.id].name
                              : schema.name}
                          </span>
                          {modelItemsData &&
                            Object.keys(modelItemsData).map((i) => (
                              <>
                                {Object.keys(modelItemsData[i]).map((o) => {
                                  if (o === viewDetailmodeldata) {
                                    return (
                                      <>
                                        {Object.keys(modelItemsData[i][o]).map(
                                          (e) => {
                                            if (
                                              e !== "is_active" &&
                                              Number(e) === schema.id
                                            ) {
                                              if (
                                                isObject(
                                                  modelItemsData[i][o][e]
                                                ) &&
                                                modelItemsData[i][o][e]
                                                  .related_field_object
                                              ) {
                                                const str = relatedToString(
                                                  modelItemsData[i][o][e]
                                                    .related_field_object
                                                );
                                                return (
                                                  <>
                                                    <span key={Math.random()}>
                                                      {str}
                                                    </span>
                                                  </>
                                                );
                                              }
                                              if (
                                                !isObject(
                                                  modelItemsData[i][o][e]
                                                )
                                              ) {
                                                return (
                                                  <>
                                                    <span key={Math.random()}>
                                                      {modelItemsData[i][o][e]}

                                                      {/* {modelItemsData[i][o][
                                                        e
                                                      ] &&
                                                      isHTML(
                                                        modelItemsData[i][o][e]
                                                      )
                                                        ? parse(
                                                            modelItemsData[i][
                                                              o
                                                            ][e]
                                                          )
                                                        : modelItemsData[i][o][
                                                            e
                                                          ]} */}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }
                                          }
                                        )}
                                      </>
                                    );
                                  }
                                })}
                              </>
                            ))}
                        </li>
                      </>
                    ))
                  : modelItemsData
                  ? modelItemsData &&
                    modelItemsData.map((key, index) => (
                      <>
                        {Object.keys(key).map((e) => (
                          <>
                            {Object.keys(modelItemsData[index][e]).map((o) => {
                              const headerTitle = modelSchema.find(
                                (i) => i.id == o
                              );

                              return (
                                <li key={Math.random()}>
                                  <span>
                                    {headerTitle && headerTitle.name
                                      ? headerTitle.name
                                      : null}
                                  </span>
                                  <span>{modelItemsData[index][e][o]}</span>
                                </li>
                              );
                            })}
                          </>
                        ))}
                        <button className="btn outline sm">EDIT</button>
                      </>
                    ))
                  : null}

                {/* {modelItemsData
                  ? Object.keys(modelItemsData).map((i) => (
                      <>
                        {Object.keys(modelItemsData[i]).map((o) => {
                          if (o === viewDetailmodeldata) {
                            return (
                              <>
                                {Object.keys(modelItemsData[i][o]).map((e) => {
                                  const headerTitle = modelSchema.find(
                                    (i) => i.id == e
                                  );
                                  return (
                                    <li key={Math.random()}>
                                      <span key={Math.random()}>
                                        {headerTitle && headerTitle.name
                                          ? headerTitle.name
                                          : null}
                                      </span>
                                      <span key={Math.random()}>
                                        {modelItemsData[i][o][e]}
                                      </span>
                                    </li>
                                  );
                                })}
                              </>
                            );
                          }
                        })}
                      </>
                    ))
                  : modelItemsData
                  ? modelItemsData &&
                    modelItemsData.map((key, index) => (
                      <>
                        {Object.keys(key).map((e) => (
                          <>
                            {Object.keys(modelItemsData[index][e]).map((o) => {
                              const headerTitle = modelSchema.find(
                                (i) => i.id == o
                              );

                              return (
                                <li key={Math.random()}>
                                  <span>
                                    {headerTitle && headerTitle.name
                                      ? headerTitle.name
                                      : null}
                                  </span>
                                  <span>{modelItemsData[index][e][o]}</span>
                                </li>
                              );
                            })}
                          </>
                        ))}
                        <button className="btn outline sm">EDIT</button>
                      </>
                    ))
                  : null} */}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default withTranslation()(TableContent);
