import React from "react";
import { LanguageState } from "./LanguageContext";
import { GlobalState } from "./GlobalState";
import frFR from "antd/lib/locale/ar_EG";
import { ConfigProvider } from "antd";
const CompinedProviders = ({ children, cookies }) => {
  return (
    <LanguageState>
      <GlobalState cookies={cookies}>{children}</GlobalState>
    </LanguageState>
  );
};
export default CompinedProviders;
