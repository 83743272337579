import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import PhoneInput from "react-phone-number-input";
import useGlobalContext from "../_context/useGlobalContext";
import "react-phone-number-input/style.css";
import { Button } from "antd";
import * as Yup from "yup";
import { signIn } from "../_apis/_post";
import { Space } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
const LoginForm = ({ t }) => {
  const { handleSignIn, handleSignOut } = useGlobalContext();

  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const initialValues = {
    email: "",
    password: "",
    mobileNumber: phoneNumber,
    radioToggleChoice: "email",
    rememberMe: "",
  };
  const validationSchema = Yup.object().shape({
    // email: Yup.string().email("Invalid email").required("Required"),
    email: Yup.string()
      .email()
      .when("radioToggleChoice", {
        is: "email",
        then: Yup.string().required("Must enter email address"),
      }),

    password: Yup.string()
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      //   "Password must contain: numbers, special characters,uppercase and lowercase letters\n"
      // )
      .required("Password is required"),
  });

  const submitFunction = async (value) => {
    setIsLoading(true);
    let userName;
    if (value.radioToggleChoice == "mobile") {
      userName = phoneNumber;
    } else {
      userName = value.email;
    }

    try {
      await handleSignIn(userName, value.password);
    } catch (e) {
      // if (e.message === "User is already signed in") {
      //   setShowSignoutButton(true);
      // } else {
      //   setTimeout(() => {
      //     setShowSignUpButton(true);
      //   }, 2000);
      // }
    }
    setIsLoading(false);
  };
  return (
    <>
      <div className="login-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="formTitle">{t("sign in")}</div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                // validationSchema={editValidationSchema}
                onSubmit={(value) => {
                  submitFunction(value);
                }}
              >
                {({ submitForm, values, errors }) => (
                  <Form>
                    <div
                      className="customRadioWrapper"
                      role="group"
                      aria-labelledby="my-radio-group"
                    >
                      <div className="customRadioBtn">
                        <Field
                          type="radio"
                          name="radioToggleChoice"
                          value="email"
                        />
                        <span>{t("email")}</span>
                      </div>
                      <div className="customRadioBtn">
                        <Field
                          type="radio"
                          name="radioToggleChoice"
                          value="mobile"
                        />
                        <span>{t("mobile")}</span>
                      </div>
                      {/* <div>Picked: {values.radioToggleChoice}</div> */}
                    </div>
                    {values.radioToggleChoice == "email" ? (
                      <div className="input-item">
                        <Space>
                          <UserOutlined />
                        </Space>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("email")}
                          name="email"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              submitForm();
                            }
                          }}
                        />
                        <ErrorMessage name="email">
                          {(msg) => (
                            <span className="fm_error" style={{ color: "red" }}>
                              {msg}
                            </span>
                          )}
                        </ErrorMessage>
                      </div>
                    ) : (
                      //   <PhoneInput
                      //     className="signUp-mobile"
                      //     international
                      //     defaultCountry="SA"
                      //     placeholder="mobile"
                      //   />
                      <PhoneInput
                        value={phoneNumber}
                        international
                        defaultCountry="SA"
                        onChange={setPhoneNumber}
                        name="mobileNumber"
                        // className="signUp-mobile"
                      />
                    )}

                    <div className="input-item">
                      <Space>
                        <LockOutlined />
                      </Space>
                      <Field
                        type="password"
                        name="password"
                        placeholder={t("password")}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            submitForm();
                          }
                        }}
                      />
                      <ErrorMessage name="password">
                        {(msg) => (
                          <span className="fm_error" style={{ color: "red" }}>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="customCheckBox">
                      <Field
                        type="checkbox"
                        name="rememberMe"
                        value="rememberMe"
                      />
                      <label>{t("remember me")}</label>
                    </div>
                    <div className="formFooter">
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        {t("sign in")}
                      </Button>
                      <div className="contant">
                        <button>{t("forgot password?")}</button>
                        <p>
                          {t("don't have an account?")}{" "}
                          <button>{t("sign up")}</button>
                        </p>
                        <button>{t("resend activation email")}</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withTranslation()(LoginForm);
