import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
// import i18n from "i18next";
import i18n from "./i18n";
import langConfig from "../_constants/lnaguage.config";
import frFR from "antd/lib/locale/ar_EG";
import { ConfigProvider } from "antd";
import styled from "styled-components";

import { setGlobalLoading, setLocalStorageLangOptions } from "../_utils/set";
import { merge, isEmpty } from "lodash";

import moment from "moment";
import {
  getApplicationTranslation,
  getInterfaceTranslation,
  getLangCode,
  getLangId,
  getLocalStorageLang,
  getLocalStorageOptions,
  getSelectLanguage,
  getStructureTranslation,
} from "../_apis/_get";

const queryString = require("query-string");
const LanguageContext = React.createContext();
const AppWrapper = styled.div``;
const LanguageState = ({ children, cookies, setCookie }) => {
  const [dataTranslation, setDataTranslation] = useState(null);
  const [structureTranslation, setStructureTranslation] = useState(null);
  const [basicTranslations, setBasicTranslations] = useState(null);
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("currentLang")
  );

  useEffect(() => {
    moment.locale(localStorage.getItem("currentLang") || "en");
    handleSetLanguage();

    return () => {};
  }, []);

  const handleSetLanguage = async () => {
    const localStorageLang = getLocalStorageLang;
    const initialLangCode =
      localStorageLang || localStorage.getItem("currentLang") || "en";

    setCurrentLang(initialLangCode);
    await initTranslations(initialLangCode);
  };

  const initTranslations = async (langCode) => {
    try {
      const langProps = langConfig;
      const localStorageLangProps = getLocalStorageOptions;

      langProps.lng = langCode;
      langProps.preload = [langCode];
      getSelectLanguage(langCode);

      if (
        localStorageLangProps &&
        localStorageLangProps.resources &&
        localStorageLangProps.resources[langCode] &&
        !isEmpty(localStorageLangProps.resources[langCode].translations)
      ) {
        setBasicTranslations(
          localStorageLangProps.resources[langCode].translations
        );
        // change for updated translation
        // await i18n.init(merge(langProps, localStorageLangProps))
        // merge(langProps, localStorageLangProps)
      } else {
      }

      const interfaceTranslate = await getAndSetInterfaceTranslation(
        getLangId(langCode)
      );
      const structureTranslate = await getAndSetStructureTranslation(
        getLangId(langCode)
      );

      // if (!langProps.resources[langCode]) {
      //   throw Error('no translations resources')
      // }

      langProps.resources[langCode].translations = {
        ...langProps.resources[langCode].translations,
        ...interfaceTranslate,
        ...structureTranslate,
      };
      await i18n.init(merge(localStorageLangProps, langProps));
      setLocalStorageLangOptions({ resources: langProps.resources });
    } catch (e) {}
  };

  const getAndSetInterfaceTranslation = async (langId, addToBunddle) => {
    const translations = {};
    const language = await getInterfaceTranslation(langId);

    await language.forEach((i) => {
      translations[i.key] = i.value;
    });

    if (addToBunddle) {
      await addBundle(getLangCode(langId), translations);
    }

    return translations;
  };
  const changeLanguage = async (lngCode, appId) => {
    setGlobalLoading(true);

    const lngId = getLangId(lngCode);
    if (appId) {
      await Promise.all([
        getAndSetInterfaceTranslation(lngId, true),
        getAndSetStructureTranslation(lngId, true),
      ]);
    } else {
      await Promise.all([
        getAndSetInterfaceTranslation(lngId, true),
        getAndSetStructureTranslation(lngId, true),
      ]);
    }

    await i18n.changeLanguage(lngCode).then(async (res) => {
      getSelectLanguage(lngCode);
    });

    setCurrentLang(lngCode);

    localStorage.setItem("currentLang", lngCode);
    setLocalStorageLangOptions({
      resources: i18n.logger.options.resources,
    });
    const localStorageLangProps = getLocalStorageOptions;
    if (
      localStorageLangProps &&
      localStorageLangProps.resources &&
      localStorageLangProps.resources[lngCode] &&
      !isEmpty(localStorageLangProps.resources[lngCode].translations)
    ) {
      setBasicTranslations(
        localStorageLangProps.resources[lngCode].translations
      );
      // change for updated translation
      // await i18n.init(merge(langProps, localStorageLangProps))
      // merge(langProps, localStorageLangProps)
    } else {
    }
    setGlobalLoading();
  };
  const addBundle = async (langCode, translations) => {
    if (i18n.language) {
      await i18n.addResourceBundle(
        langCode || "en",
        "translations",
        translations,
        true
      );
    }
  };
  const getAndSetStructureTranslation = async (langId, addToBunddle) => {
    const translations = await getStructureTranslation(langId);
    setStructureTranslation(translations);
    if (addToBunddle) {
      await addBundle(getLangCode(langId), translations);
    }

    return translations;
  };
  // add for design model translation
  const getAndSetApplicationTranslation = async (
    appId,
    langId,
    addToBunddle
  ) => {
    const translations = await getApplicationTranslation(appId, langId);
    setDataTranslation(translations);
    let data = {};

    if (translations && addToBunddle) {
      data[appId] = translations;
      addBundle(getLangCode(langId), data);
    }

    return translations;
  };
  useEffect(() => {
    const fetchStructure = async () => {
      await getAndSetApplicationTranslation(3, getLangId(currentLang));
    };
    fetchStructure();
  }, [currentLang]);
  useEffect(() => {
    const fetchStructure = async () => {
      await getAndSetStructureTranslation(3, getLangId(currentLang));
    };
    fetchStructure();
  }, [currentLang]);
  return (
    <I18nextProvider i18n={i18n}>
      <LanguageContext.Provider
        value={{
          currentLang,
          changeLanguage,
          getAndSetStructureTranslation,
          getAndSetApplicationTranslation,
          dataTranslation,
          structureTranslation,
          basicTranslations,
        }}
      >
        <ConfigProvider locale={currentLang == "ar" ? frFR : null}>
          {/* <GlobalStyles lang={currentLang} /> */}
          <AppWrapper dir={currentLang == "ar" ? "rtl" : "ltr"}>
            {children}
          </AppWrapper>
        </ConfigProvider>
      </LanguageContext.Provider>
    </I18nextProvider>
  );
};

export { LanguageState, LanguageContext };
