import React from "react";

const CreateDataForm1 = () => {
  return <>
    <section className="create-data-form-sec create-data-form-sec-2 relative">
      <img className="sec-bg-img" src="assets/images/hexagone-group.png" alt="" />

      <div className="container">
        <div className="row">
          <div className="col col-11 mx-auto">
            <div className="row">
              <div className="col col-12">
                <div class="btn-language-wrapper">
                  <ul class="header-language">
                    <li class="active">english</li>
                    <li class="">العربية</li>
                  </ul>
                </div>
              </div>
              {/* <div className="col col-6">
            <img src="assets/images/doc-1.png" alt="" />
          </div> */}
              <div className="col col-6">
                <div className="form-wrapper create-data-form">
                  <div className="form-title">
                    <h2>Create a Data</h2>
                  </div>
                  <div className="create-data-form-filed">
                    <form>
                      <div className="input-item number small">
                        <label htmlFor="">No</label>
                        <input type="text" name="" id="" />
                      </div>
                      <div className="input-item date small">
                        <label htmlFor="">Date</label>
                        <input type="text" name="" id="" />
                      </div>
                      <div className="input-item deal">
                        <label htmlFor="">Date</label>
                        <input type="text" name="" id="" />
                      </div>
                      <div className="input-item w-full">
                        <label htmlFor="">Date</label>
                        <textarea name="" id="" rows="5"></textarea>
                      </div>
                      <div className="input-item approve small">
                        <label htmlFor="">Approved</label>
                        <div className="switch">
                          <input type="checkbox" name="" id="" />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-item total-amount small">
                        <label htmlFor="">Total Amount</label>
                        <input type="text" name="" id="" />
                      </div>
                      <div className="input-item partner-ammount small">
                        <label htmlFor="">Partner Amount</label>
                        <input type="text" name="" id="" />
                      </div>
                      <div className="input-item uploaded-document w-full">
                        <label htmlFor="">Quote Document</label>
                        <button className="btn primary add-btn">Add</button>
                        <ul>
                          <li>
                            <img src="assets/images/file.svg" alt="" />
                            <div className="icon-close">
                              <img src="assets/images/close.svg" alt="" />
                            </div>
                          </li>
                          <li>
                            <img src="assets/images/file.svg" alt="" />
                            <div className="icon-close">
                              <img src="assets/images/close.svg" alt="" />
                            </div>
                          </li>
                        </ul>
                      </div>

                      <button className="btn primary sm">SUBMIT</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>;
};
export default CreateDataForm1;
