import styled from "styled-components";

export const CloudWrapper = styled.div`
  .loader_wrapper {
    width: 100%;
    min-height: 400px;
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  #Cloud {
    max-width: 100%;
    max-height: 100%;
    // display: none;
    // stroke-width: 2;
    width: 80px;
    height: 80px;
    // position: relative;
    // left: 50%;
    // transform: translateX(-60%);
    // right: 0;
    // bottom: 0;

    margin: auto;
    // fill-opacity: 0;
    stroke: #fff;
    fill: #fff;
    stroke-opacity: 1;
    stroke-width: 10px;
    // stri
    stroke-dasharray: 1450px;
    stroke-dashoffset: 1450px;
    animation-name: draw;
    animation-duration: 1.4s;
    // animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;

    &.purple {
      stroke: #465b95;
      fill: #465b95;
      width: 60px;
      height: 60px;
    }

    path {
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    }
  }

  @keyframes draw {
    0% {
      // stroke-dashoffset: 1200px;
      fill-opacity: 0;
      transform: rotateY(0deg);
    }

    50% {
      stroke-dashoffset: 0;
      fill-opacity: 0;
      // transform: translate(10px, 5px);
    }

    100% {
      stroke-dashoffset: 0px;
      stroke-opacity: 0;
      fill-opacity: 1;
      transform: rotateY(360deg);
    }
  }

  @media (max-width: 768px) {
    .loader_wrapper {
      position: relative;
      display: flex;
    }

    #Cloud {
      margin: auto !important;
      position: relative;
      left: 0%;
      transform: translateX(0);
    }

    @keyframes draw {
      0% {
        // stroke-dashoffset: 1200px;
        fill-opacity: 0;
        // transform: rotateY(0deg);
      }

      50% {
        stroke-dashoffset: 0;
        fill-opacity: 0;
        // transform: translate(10px, 5px);
      }

      100% {
        stroke-dashoffset: 0px;
        stroke-opacity: 0;
        fill-opacity: 1;
        // transform: rotateY(360deg);
      }
    }
  }
`;
