import React, { useContext } from "react";

import { LanguageContext } from "./LanguageContext";
function useLang() {
  const context = useContext(LanguageContext);

  const isArabicLang = context.currentLang === "ar";
  const dir = context.currentLang === "ar" ? "rtl" : "ltr";
  return {
    ...context,
    isArabicLang,
    dir,
  };
}

export default useLang;
