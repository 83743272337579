import React from "react";
import '../assets/all.css'
import RequestDataForm from "../component/requestDataForm.component";
import RequestDataForm1 from "../component/requestDataForm1.component";

const RequestData = () => {
  return <>

    {/* <RequestDataForm /> */}
    <RequestDataForm1 />


  </>;
};
export default RequestData;
