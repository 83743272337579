export default {
  lng: "ar",
  resources: {
    en: {
      translations: {},
    },
    ar: {
      translations: {},
    },
    tr: {
      translations: {},
    },
  },
  lowerCaseLng: true,
  cleanCode: true,
  preload: ["ar"],
  fallbackLng: "ar",
  debug: false,
  ns: ["translations"],
  defaultNS: ["translations"],
  keySeparator: ".",
  wait: true,
  react: {
    wait: true,
  },
};
