import { Table } from "antd";
import { isObject } from "lodash";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import useLang from "../_context/useLang";
import { relatedToString } from "../_utils/set";

const Items = ({ props, t }) => {
  const {
    currentItems,
    modelSchema,
    currentModelPerm,
    handleViewDetailsModelData,
    handleViewModelData,
  } = props;
  const [tableColumn, setTableColumn] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const { dataTranslation } = useLang();
  useEffect(() => {
    let columns = [];
    if (modelSchema) {
      modelSchema.forEach((schema, i) => {
        let col = {
          title: t(
            // schema.name ? schema.name : null
            dataTranslation &&
              dataTranslation.field &&
              dataTranslation.field[schema.id] &&
              dataTranslation.field[schema.id].name
              ? dataTranslation.field[schema.id].name
              : schema.name
          ),
          dataIndex: schema.id,
          key: Math.random(),
          isActive: true,
        };
        columns.push(col);
      });
      columns.push({
        title: t("action"),
        key: Math.random(),
        render: (record) => {
          return (
            <span key={Math.random()}>
              {currentModelPerm && currentModelPerm.PUT === true ? (
                <span
                  className="view-Btn"
                  onClick={() => handleViewModelData(record.id)}
                  key={Math.random()}
                >
                  {t("edit")}
                </span>
              ) : null}
              {currentModelPerm && currentModelPerm.GET === true ? (
                <span
                  className="view-Btn"
                  onClick={() => handleViewDetailsModelData(record.id)}
                  key={Math.random()}
                >
                  {t("view")}
                </span>
              ) : null}
            </span>
          );
        },
      });
    }
    setTableColumn(columns);
  }, [modelSchema]);

  useEffect(() => {
    if (currentItems) {
      const data = [];
      currentItems.forEach((items) => {
        Object.keys(items).forEach((item) => {
          let obj = {};
          Object.keys(items[item]).forEach((key) => {
            if (
              isObject(items[item][key]) &&
              items[item][key].related_field_object
            ) {
              obj[key] = relatedToString(items[item][key].related_field_object);
              obj.id = item;
            } else {
              obj[key] = items[item][key];
              obj.id = item;
            }
          });
          data.push(obj);
        });
      });
      setActiveData(data);
    }
  }, [currentItems]);
  let temp;
  return (
    <>
      <Table
        key={Math.random()}
        columns={tableColumn}
        dataSource={activeData}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "15"],
        }}
      />
    </>
  );
};

const TableItems = ({ itemsPerPage, props, t }) => {
  const [currentItems, setCurrentItems] = useState(null);

  const { modelItemsData, modelSchema } = props;

  props = { ...props, currentItems };

  useEffect(() => {
    if (modelItemsData) {
      setCurrentItems(modelItemsData);
    }
  }, [modelItemsData]);

  return (
    <>
      <Items
        currentItems={currentItems}
        modelSchema={modelSchema}
        props={props}
        t={t}
        key={Math.random()}
      />
    </>
  );
};

export default withTranslation()(TableItems);
