import React, { useEffect, useState } from "react";
import "../assets/all.css";
import LoginForm from "./LoginForm.component";
import SignUpForm from "./SignUpForm.component";
import useGlobalContext from "../_context/useGlobalContext";
import useLang from "../_context/useLang";
import { Select } from "antd";
import { withTranslation } from "react-i18next";
const { Option } = Select;
const Header = ({ t, companyDetails }) => {
  const [isLoginButton, setIsLoginButton] = useState(false);
  const { changeLanguage, currentLang, basicTranslations } = useLang();
  const { handleSignOut } = useGlobalContext();
  const [isSignUp, setIsSignUp] = useState(false);
  const handleLogin = () => {
    setIsLoginButton(true);
  };
  const handleSignUp = () => {
    setIsSignUp(true);
  };
  const handleClose = () => {
    setIsLoginButton(false);
    setIsSignUp(false);
  };

  const getUser = localStorage.getItem("user");
  useEffect(() => {
    setIsLoginButton(false);
    setIsSignUp(false);
  }, [getUser]);
  const handleChangeLanguage = async (value) => {
    await changeLanguage(value);
    localStorage.setItem("appCurrentLang", value);
  };
  return (
    <>
      <header className="header" id="Header" key={Math.random()}>
        <div className="header-inner">
          <div className="container">
            <div className="row">
              <div className="col col-6">
                <div className="header-info-wrapper">
                  <div className="img">
                    <img src="assets/images/header-img.png" alt="" />
                  </div>

                  <div className="content" key={Math.random()}>
                    <h3>{t(companyDetails && companyDetails.name)}</h3>
                    <a>{t(companyDetails && companyDetails.email)}</a>
                    <span>{t(companyDetails && companyDetails.mobile)}</span>
                    <br />
                    <span>{t(companyDetails && companyDetails.address)}</span>
                    <br />
                    <span>
                      {t("tax no")}:{" "}
                      {t(companyDetails && companyDetails.tax_no)}
                    </span>
                    <br />
                    <span>
                      {t("cr")} : {t(companyDetails && companyDetails.cr)}
                    </span>
                    <a
                      href={
                        companyDetails && companyDetails.website
                          ? companyDetails.website
                          : null
                      }
                    >
                      {t("website")} :{" "}
                      {companyDetails && companyDetails.website
                        ? companyDetails.website
                        : null}
                    </a>
                  </div>
                </div>
              </div>

              <div className="col col-6">
                <div className="btn-language-wrapper">
                  <div className="header-auth-btn-wrap mx-auto">
                    {getUser ? (
                      <button
                        className="btn primary auth-sign-up"
                        onClick={handleSignOut}
                      >
                        {/* {t(
                          basicTranslations && basicTranslations["logout"]
                            ? basicTranslations["logout"]
                            : "Logout"
                        )} */}
                        {t("logout")}
                      </button>
                    ) : (
                      <>
                        <button
                          className="btn auth-login"
                          onClick={handleLogin}
                        >
                          {t("sign in")}
                          {/* {t(
                            basicTranslations && basicTranslations["login"]
                              ? basicTranslations["login"]
                              : "Login"
                          )} */}
                        </button>
                        <button
                          className="btn primary auth-sign-up"
                          onClick={handleSignUp}
                        >
                          {t("sign up")}
                          {/* {t(
                            basicTranslations && basicTranslations["sign up"]
                              ? basicTranslations["sign up"]
                              : "Sign up"
                          )} */}
                        </button>
                      </>
                    )}
                  </div>

                  {/* <ul className="header-language">
                    <li className="active">english</li>
                    <li className="">العربية</li>
                  </ul> */}

                  <Select
                    value={currentLang ? currentLang : "en"}
                    onChange={handleChangeLanguage}
                  >
                    <Option value="en">{t("English")}</Option>
                    <Option value="ar">{t("العربية")}</Option>
                  </Select>
                </div>
              </div>

              {isLoginButton ? (
                <div className="cs-form-modal loginModal">
                  <div className="container">
                    <div className="row">
                      <div className="loginBG-wrapper">
                        <button
                          className="closeBtn"
                          onClick={handleClose}
                        ></button>
                        <div className="content-view-modal-wrapper">
                          <div className="content-view-modal-inner common-box">
                            <LoginForm />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {isSignUp ? (
                <div className="cs-form-modal loginModal">
                  <div className="container">
                    <div className="row">
                      <div className="loginBG-wrapper">
                        <button
                          className="closeBtn"
                          onClick={handleClose}
                        ></button>
                        <div className="content-view-modal-wrapper">
                          <div className="content-view-modal-inner common-box">
                            {/* <LoginForm /> */}
                            <SignUpForm />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default withTranslation()(Header);
