import { isObject } from "lodash";

export const isGridContainer = (container) => container.type === 3;
export const isListOptionField = (code) =>
  code === "list_options_data" ? true : false;
export const isLangArabic = (lng) => lng === "ar";
export const isWidgetField = (code) => (code === "widget" ? true : false);
export const isNumberField = (data_type) =>
  isObject(data_type) ? data_type.id === 3 : data_type === 3 ? true : false;
export const isDateField = (data_type) =>
  isObject(data_type) ? data_type.id === 5 : data_type === 5 ? true : false;
export const isDateTimeField = (data_type) =>
  isObject(data_type) ? data_type.id === 10 : data_type === 10 ? true : false;
export const isTimeField = (data_type) =>
  isObject(data_type) ? data_type.id === 6 : data_type === 6 ? true : false;
export const isTimeStampField = (data_type) =>
  isObject(data_type) ? data_type.id === 11 : data_type === 11 ? true : false;
export const isListField = (data_type) =>
  isObject(data_type) ? data_type.id === 7 : data_type === 7 ? true : false;
export const isRelatedField = (data_type) =>
  isObject(data_type) ? data_type.id === 9 : data_type === 9 ? true : false;
export const isLongTextField = (data_type) =>
  isObject(data_type) ? data_type.id === 2 : data_type === 2 ? true : false;
export const isBooleanField = (data_type) =>
  isObject(data_type) ? data_type.id === 4 : data_type === 4 ? true : false;
export const isFileField = (data_type) =>
  isObject(data_type) ? data_type.id === 8 : data_type === 8 ? true : false;
export const isStringField = (data_type) =>
  isObject(data_type) ? data_type.id === 1 : data_type === 1 ? true : false;
export const isAutoIncrementField = (field) =>
  field.properties.find((i) => i.property === 8 && i.value === "1");
export const isRequiredField = (field) =>
  field.properties.find((i) => i.property === 1 && i.value === "1") &&
  !isAutoIncrementField(field);
export const isHTML = (str) =>
  !(str || "")
    // replace html tag with content
    .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, "")
    // remove remaining self closing tags
    .replace(/(<([^>]+)>)/gi, "")
    // remove extra space at start and end
    .trim();
