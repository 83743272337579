const { trim } = require("lodash");

/**
 * Config
 */
const env = window.location.hostname.includes("dashboard.1daycloud.com")
  ? "prod"
  : "dev";
/**
 * Local
 */
const localConfig = {
  baseUrl: "http://api.oneday.test/v3/",
};
/**
 * Dev
 */
const devConfig = {
  baseUrl: "https://testapi.1daycloud.com/v3/",
};
/**
 * Prod
 */
const prodConfig = {
  baseUrl: "https://api.1daycloud.com/v3/",
};

module.exports =
  env === "prod" || trim(process.env.REACT_APP_ENV) === "prod"
    ? prodConfig
    : trim(process.env.REACT_APP_ENV) === "local"
    ? localConfig
    : devConfig;
