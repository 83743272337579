import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import "../assets/spinner.css";
import "antd/dist/antd.css";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default function LoadingSpinner() {
  return (
    <div
      style={{
        height: `200px`,
        display: `flex`,
        justifyContent: `center`,
      }}
    >
      <div style={{ margin: `auto`, textAlign: `center` }}>
        <Spin indicator={antIcon} />
      </div>
    </div>
  );
}
