import React from "react";
import * as S from "./styled";

const Cloud = ({ color }) => {
  return (
    <S.CloudWrapper>
      <svg
        width="519"
        height="308"
        viewBox="0 0 519 308"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="Cloud"
        className={`${color}`}
      >
        <path
          d="M429.132 136.651H427.943V134.275C424.297 118.842 415.608 105.065 403.25 95.1224C390.892 85.1798 375.571 79.6391 359.71 79.3765C352.763 79.42 345.866 80.5425 339.264 82.7037H337.6V81.0401C325.18 59.6765 307.289 42.0075 285.769 29.8521C264.248 17.6967 239.876 11.494 215.16 11.8827C209.827 11.8393 204.499 12.2367 199.231 13.071C169.28 16.9599 141.365 30.3453 119.584 51.2618C97.804 72.1782 83.3058 99.5239 78.2185 129.284V130.948H76.5543C57.8033 135.088 41.0847 145.638 29.2801 160.778C17.4755 175.919 11.3245 194.701 11.8873 213.889C11.7596 214.994 11.7596 216.111 11.8873 217.216V219.355C13.0337 240.271 22.0999 259.967 37.2452 274.445C52.3905 288.922 72.4797 297.095 93.4343 297.306H429.132C449.534 296.192 468.733 287.306 482.781 272.475C496.829 257.644 504.658 237.997 504.658 217.573C504.658 197.148 496.829 177.501 482.781 162.67C468.733 147.839 449.534 138.953 429.132 137.84V136.651Z"
          fill=""
          stroke=""
          strokeMiterlimit="10"
        />
      </svg>
    </S.CloudWrapper>
  );
};

export default Cloud;
